<template>
  <div class="modal modal_wrap"  @keyup.esc="close">
    <div class="modal_popup">
      <p class="text text_white">Are you sure you want to delete this item?
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
      </span>
      </p>
      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="close"><svg-icon name="solid/step-backward" /> Back</button>
        <button class="btn btn_transparent" @click="deleteIt"><svg-icon name="solid/trash-xmark" /> Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DeleteModalGlobal",
    methods: {
      close() {
        this.$store.commit('deleteModalGlobal/setStatus', false);
        this.$store.commit('deleteModalGlobal/setParams', {
          itemType: String,
          action: String,
          actionAfter: {},
          actionData: {}
        });
      },
      deleteIt() {
        this.$store.dispatch('deleteModalGlobal/deleteId');
      }
    },
    computed: {
      ItemName() {
        return this.$store.getters['deleteModalGlobal/itemName'];
      },
      status() {
        return this.$store.getters['deleteModalGlobal/get']('status');
      }
    }
  }
</script>

<style scoped>
  .justify-content-between {
    display: flex;
  }

  p.text{
    position: relative;
    padding-right: 30px;
  }

  .modal__close{
    top: 0;
    right: 0;
  }
</style>
